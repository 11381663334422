import { Injectable, NgZone } from '@angular/core';
import firebase from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from "@angular/router";
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { User, Roles } from "./service.model";

@Injectable({
  providedIn: 'root'
})

export class NgAuthService {
  user$: Observable<User | null>;
  user_id: any;
  //userState: any;

  admin?: boolean
  editor?:boolean
  subscriber?: boolean

  constructor(
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone,
    private toastr: ToastrService
  ) {
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        this.user_id = user.uid
        if (user) {
          localStorage.setItem('user', JSON.stringify(user));
          JSON.parse(localStorage.getItem('user'));
          return this.afs.doc<User>(`ADMIN/${user.uid}`).valueChanges();
        } else {
          localStorage.setItem('user', null);
          JSON.parse(localStorage.getItem('user'));
          return of(null);
        }
      })
    )
    /*       this.afAuth.authState.subscribe(user => {
            if (user) {
              this.userState = user;
              localStorage.setItem('user', JSON.stringify(this.userState));
              JSON.parse(localStorage.getItem('user'));
            } else {
              localStorage.setItem('user', null);
              JSON.parse(localStorage.getItem('user'));
            }
          }) */
    /*       this.userState = this.afAuth.authState.pipe(
            switchMap(user => {
              if (user) {
                localStorage.setItem('user', JSON.stringify(user));
                JSON.parse(localStorage.getItem('user'));
                return this.afs.doc<User>(`ADMIN/${user.uid}`).valueChanges();
              } else {
                localStorage.setItem('user', null);
                JSON.parse(localStorage.getItem('user'));
                return of(null);
              }
            })
          ); */
  }

  SignIn(email, password) {
    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['/dashboard']).then((e) => {
            if (e) {
              this.toastr.success("Login Successful", 'Success');
              console.info("Login Successful");
            }
          })
        });
        // this.SetUserData(result.user);
      }).catch((error) => {
        console.error('Login failed');
        this.toastr.error(error.message, "Login failed");
        // window.alert(error.message);
      })
  }

  SignUp(email, password) {
    return this.afAuth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        this.SendVerificationMail();
        this.SetUserData(result.user);
      }).catch((error) => {
        //window.alert(error.message)
        this.toastr.error(error.message)
      })
  }

  // Update properties on the user document
  updateUser(user: User, data: any) {
    return this.afs.doc(`ADMIN/${user.uid}`).update(data)
  }

  SendVerificationMail() {
    return this.afAuth.currentUser.then(u => u.sendEmailVerification())
      .then(() => {
        this.router.navigate(['email-verification']);
      })
  }

  ForgotPassword(passwordResetEmail) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      }).catch((error) => {
        window.alert(error)
      })
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null /* && user.emailVerified !== true */)
  }

  GoogleAuth() {
    return this.AuthLogin(new firebase.auth.GoogleAuthProvider());
  }

  AuthLogin(provider) {
    return this.afAuth.signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['/dashboard']);
        })
        this.SetUserData(result.user);
      }).catch((error) => {
        window.alert(error)
      })
  }

  
  // Sets user data to firestore after succesful login
  private SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`ADMIN/${user.uid}`);

    const data: User = {
      uid: user.uid,
      email: user.email || null,
      displayName: user.displayName || '',
      photoURL: user.photoURL || '',
      emailVerified: user.emailVerified,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      roles: {
        admin: this.admin,
        editor: this.editor,
        subscriber: this.subscriber,
      }

    };
    return userRef.set(data, { merge: true });
  }

  //Ability and role authorization
  canRead(user: User): boolean {
    const allowed = ['admin', 'editor', 'subscriber']
    return this.checkAuthorization(user, allowed)
  }

  canEdit(user: User): boolean {
    const allowed = ['admin', 'editor']
    return this.checkAuthorization(user, allowed)
  }

  canDelete(user: User): boolean {
    const allowed = ['admin']
    return this.checkAuthorization(user, allowed)
  }
  //determines if user has matching role
  private checkAuthorization(user: User, allowedRoles: string[]): boolean {
    if (!user) return false
    for (const role of allowedRoles) {
      if (user.roles[role]) {
        return true
      }
    }
    return false
  }

  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['/login'])
    })
  }
}
import { Component, OnInit } from '@angular/core';
import { NgAuthService } from "../core/ng-auth.service";
import { Validators, FormBuilder, FormGroup, FormControl, FormGroupDirective, NgForm, } from '@angular/forms';
//import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import {MatDialog} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr'; 
import {ErrorStateMatcher} from '@angular/material/core';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})

export class SignInComponent implements OnInit {

/*   emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  passwordFormControl = new FormControl('', [
    Validators.required,
  ]); */

  matcher = new MyErrorStateMatcher();
  hide = true;
  loginForm: FormGroup;

  constructor(private toastr: ToastrService, public dialog: MatDialog,  public ngAuthService: NgAuthService, public fb: FormBuilder) {
    this.loginForm = fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
    });
  }

/*   userFormValidation() {
    this.loginForm = this.fb.group({
      email: new FormControl('', Validators.compose([Validators.required, Validators.email
    ])),
    password: new FormControl('', Validators.compose([Validators.required,Validators.minLength(6)]))

    })

  } */

  validation_messages = {
    'email': [
      { type: 'required', message: 'Email is required' },
      { type: 'pattern', message: 'Please enter a valid email address' }
    ],
    'password': [
      { type: 'required', message: 'Password is required' },
      { type: 'minlength', message: 'Password must be at least 6 characters long' }
    ],

  };

  /* Forgot Password Modal */
  openModal() {
    this.dialog.open(ForgotPasswordComponent, {
      height: '350px',
      width: '500px',
    });
  }

  login() {
    this.ngAuthService.SignIn(this.loginForm.value['email'], this.loginForm.value['password'])
  }
  ngOnInit() { }

}


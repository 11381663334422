import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Dashboard',  icon: 'ni-tv-2 text-primary', class: '' },
    { path: '/agents', title: 'Manage Agents',  icon:'ni-single-02 text-yellow', class: '' },
    { path: '/ads', title: 'Manage Adverts',  icon:'ni-notification-70 text-info', class: '' },
    { path: '/support', title: 'Manage Chat Support',  icon:'ni-headphones text-danger', class: '' },
    { path: '/feeds', title: 'Manage Feeds',  icon:'ni-single-copy-04 text-info', class: '' },
    { path: '/luxury', title: 'Manage Luxury',  icon:'ni-cart text-danger', class: '' },
    /* { path: '/feeds', title: 'Manage Quick Access',  icon:'ni-atom text-info', class: '' }, */
    { path: '/st-report', title: 'Manage Situation Report',  icon:'ni-bell-55 text-info', class: '' },
    { path: '/sys-agent', title: 'Manage System Agents',  icon:'ni-single-02 text-info', class: '' },
    { path: '/transactions', title: 'Manage Transactions',  icon:'ni-credit-card text-green', class: '' },
    { path: '/users', title: 'Manage Users',  icon:'ni-circle-08 text-pink', class: '' },
   /*  { path: '/feeds', title: 'Manage Tra_Ads',  icon:'ni-single-copy-04 text-info', class: '' }, */
    { path: '/visa-info', title: 'Manage Visa Information',  icon:'ni-single-copy-04 text-info', class: '' },
    { path: '/visa-app', title: 'Manage Visa Application',  icon:'ni-single-copy-04 text-green', class: '' },

   // { path: '/icons', title: 'Icons',  icon:'ni-planet text-blue', class: '' },
/*     { path: '/maps', title: 'Maps',  icon:'ni-pin-3 text-orange', class: '' },
    { path: '/tables', title: 'Tables',  icon:'ni-bullet-list-67 text-red', class: '' }, */


];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public menuItems: any[];
  public isCollapsed = true;

  constructor(private router: Router) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
   });
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { NgAuthService } from "../core/ng-auth.service";
//import { MdbModalRef } from 'mdb-angular-ui-kit';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})

export class VerifyEmailComponent implements OnInit {

  constructor(/* public modalRef: MdbModalRef<VerifyEmailComponent>,  */
              public ngAuthService: NgAuthService,
              @Inject(DOCUMENT) private _document: Document
              ) { }

  refreshPage() {
    this._document.defaultView.location.reload();
  }
  ngOnInit(): void {
  }

}

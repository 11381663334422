import { Component, OnInit, Inject } from '@angular/core';
import { NgAuthService } from "../core/ng-auth.service";
//import { MdbModalRef } from 'mdb-angular-ui-kit';
import {MatDialogRef} from '@angular/material/dialog';
import { Validators, FormBuilder, FormGroup, FormControl, FormGroupDirective, NgForm, } from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  matcher = new MyErrorStateMatcher();

  constructor(/* public modalRef: MdbModalRef<ForgotPasswordComponent>, */ public ngAuthService: NgAuthService,
    public dialogRef: MatDialogRef<ForgotPasswordComponent>) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    
  }

}

import { Component, OnInit } from '@angular/core';
import { NgAuthService } from "../core/ng-auth.service";
import { Validators, AbstractControl, ValidatorFn, FormBuilder, FormGroup, FormControl, FormGroupDirective, NgForm, } from '@angular/forms';
//import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit';
import { VerifyEmailComponent } from '../verify-email/verify-email.component';
import { MatDialog } from '@angular/material/dialog';
import { PasswordValidator } from './password.validator';
import { ErrorStateMatcher } from '@angular/material/core';
import { ToastrService } from "ngx-toastr";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})

export class SignUpComponent implements OnInit {

  matcher = new MyErrorStateMatcher();
  hide = true;
  hide2 = true;
  registrationForm: FormGroup;
  closeModal:any;

  constructor(public dialog: MatDialog, public ngAuthService: NgAuthService, public fb: FormBuilder, private toastr: ToastrService, public activeModal: NgbActiveModal) {
    /*     this.registrationForm = fb.group({
          email: [null, [Validators.required, Validators.email]],
          password: [null, Validators.required],
          confirmPassword: [null, Validators.required, this.confirmEquals()],
        }); */
  }

  openConfirmationModal() {
    this.dialog.open(VerifyEmailComponent, {
      height: '350px',
      width: '500px',
    });
  }

  ngOnInit() {
    this.registrationFormValidation();
  }

  rol: string;
  role(data: string) {
    this.rol = data;
    // console.log("listener", data)
    if (data == 'Admin') {
      this.ngAuthService.admin = true
      this.ngAuthService.editor = false
      this.ngAuthService.subscriber = false
    }
    else if (data == 'Editor') {
      this.ngAuthService.admin = false
      this.ngAuthService.editor = true
      this.ngAuthService.subscriber = false
    }
    else if (data == 'Subscriber') {
      this.ngAuthService.admin = false
      this.ngAuthService.editor = false
      this.ngAuthService.subscriber = true
    }

  }
  /* [[[[[[[[[[[[[[[[[[[[[[[[[[[[[[ BEGINING OF SIGN-UP ]]]]]]]]]]]]]]]]]]]]]]]]]]]]]] */
  signup() {
    this.ngAuthService.SignUp(this.registrationForm.value['email'], this.registrationForm.value['password'])
      .then(res => {
        console.log('Your account has been created');
        this.toastr.success(this.rol + ' has been sucessfully added', 'Success')
      }, err => {
        console.log('Account Creation Failed');
        this.toastr.error('Account Creation Failed')
      })
  }

  registrationFormValidation() {
    this.registrationForm = this.fb.group({
      email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
      password: new FormControl('', Validators.compose([Validators.required, Validators.minLength(6)])),
      confirmPassword: new FormControl('', Validators.compose([Validators.required, Validators.minLength(6)])),
    },
      {
        validator: PasswordValidator.MatchPassword // custom validation
      }
    );
  }

  validation_messages = {
    'email': [
      { type: 'required', message: 'Email is required' },
      { type: 'email', message: 'Enter a valid email' }
    ],
    'password': [
      { type: 'required', message: 'Password is required' },
      { type: 'minlength', message: 'Password must be at least 6 characters long' }
    ],
    'confirmPassword': [
      { type: 'required', message: 'Confirm Password is required' },
      { type: 'minlength', message: 'Confirm Password must be at least 6 characters long' }
    ],
  };



}


  import { Component, OnInit } from '@angular/core';
  import { NgAuthService } from "../core/ng-auth.service";
  import { Validators, AbstractControl, ValidatorFn, FormBuilder, FormGroup, FormControl, FormGroupDirective, NgForm, } from '@angular/forms';
  //import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit';
  import { VerifyEmailComponent } from '../verify-email/verify-email.component';
  import {MatDialog} from '@angular/material/dialog';
  import { Router, Params } from '@angular/router';
  
  @Component({
    selector: 'app-admin-profile-update',
    templateUrl: './admin-profile-update.component.html',
    styleUrls: ['./admin-profile-update.component.scss']
  })
  export class AdminProfileUpdateComponent implements OnInit {
    
   
    hide = true;
    hide2 = true;
    adminForm: FormGroup;
    errorMessage: string = '';
    successMessage: string = '';
  
  
    constructor( public dialog: MatDialog,  public ngAuthService: NgAuthService, public fb: FormBuilder, private router: Router) {
  /*     this.adminForm = fb.group({
        email: [null, [Validators.required, Validators.email]],
        password: [null, Validators.required],
        confirmPassword: [null, Validators.required, this.confirmEquals()],
      }); */
    }
  
    openConfirmationModal() {
      this.dialog.open(VerifyEmailComponent, {
        height: '350px',
        width: '500px',
      });
    }
  
    ngOnInit() { 
      this.adminFormValidation();
    }
  
/* [[[[[[[[[[[[[[[[[[[[[[[[[[[[[[ BEGINING OF PROFILE UPDATE ]]]]]]]]]]]]]]]]]]]]]]]]]]]]]] */
get surname() { return this.adminForm.get('surname') }
get middlename() { return this.adminForm.get('middleName') }
get firstname() { return this.adminForm.get('firstName') }
get gender() { return this.adminForm.get('gender') }
get phone() { return this.adminForm.get('phoneNo') }
get address() { return this.adminForm.get('address') }
get lga() { return this.adminForm.get('lga') }
get state() { return this.adminForm.get('state') }

// Step 2
setProfile(user) {
  return this.ngAuthService.updateUser(user, 
    { 
      surname:  this.surname.value,
      middleName:  this.middlename.value,
      firstName:  this.firstname.value,
      gender:  this.gender.value,
      phoneNo:  this.phone.value,
      address:  this.address.value, 
      lga:  this.lga.value, 
      state:  this.state.value, 
    }).then(() => {
    this.router.navigate(['/user-profile']);
  });
}
  
  adminFormValidation(){
    this.adminForm = this.fb.group({
      'surname': ['', [ Validators.required, Validators.pattern(/^[a-zA-Z]*$/),Validators.minLength(3) ] ],
      'firstName': ['', [ Validators.required, Validators.pattern(/^[a-zA-Z]*$/),Validators.minLength(3) ] ],
      'middleName': ['', [ Validators.pattern(/^[a-zA-Z]*$/),Validators.minLength(3) ] ],
      'gender': ['', [ Validators.required, Validators.pattern(/^[a-zA-Z]*$/),Validators.minLength(3) ] ],
      'phoneNo': ['', [ Validators.required,Validators.pattern(/^[0-9_]{11,11}$/) ] ],
      'address': ['', [ Validators.required,Validators.pattern(/^[A-Za-z0-9_]+/) ] ],
      'lga': ['', [ Validators.required, Validators.pattern(/^[a-zA-Z]*$/),Validators.minLength(3) ]],
      'state': ['', [ Validators.required, Validators.pattern(/^[a-zA-Z]*$/),Validators.minLength(3) ] ],
    }
    );
  }
  
  validation_messages = {
    'surname': [
      { type: 'required', message: 'Surname is required' },
      { type: 'pattern', message: 'Must be min of 3 alphabets, number or symbols are invalid' }
    ],
    'firstName': [
      { type: 'required', message: 'First Name is required' },
      { type: 'pattern', message: 'Must be min of 3 alphabets, number or symbols are invalid' }
    ],
    'middleName': [
      { type: 'required', message: 'Middle Name is required' },
      { type: 'pattern', message: 'Must be min of 3 alphabets, number or symbols are invalid' }
    ],
    'gender': [
      { type: 'required', message: 'Gender is required' },
      { type: 'pattern', message: 'Must be min of 3 alphabets, number or symbols are invalid' }
    ],
    'phoneNo': [
      { type: 'required', message: 'Phone Number is required' },
      { type: 'pattern', message: 'Must not be less or more than 11 digits, alphabets are invalid' }
    ],
    'address': [
      { type: 'required', message: 'Address is required' },
      { type: 'pattern', message: 'Must be min of 3 alphabets, number or symbols is invalid' }
    ],
    'lga': [
      { type: 'required', message: 'LGA is required' },
      { type: 'pattern', message: 'Must be min of 3 alphabets, number or symbols is invalid' }
    ],
    'state': [
      { type: 'required', message: 'Satte is required' },
      { type: 'pattern', message: 'Must be min of 3 alphabets, number or symbols is invalid' }
    ],
  };
  
  
  
  }
  
  